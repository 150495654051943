import feathersClient, { makeServicePlugin, BaseModel } from '@/api-client'

class MonthlyTotal extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'MonthlyTotal'
}

const servicePath = 'monthly-totals'
const servicePlugin = makeServicePlugin({
  Model: MonthlyTotal,
  // IMPORTANT
  whitelist: ['$eager', '$iRegexp', '$regex', '$options'],
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
