import Vue from 'vue'
import VueRouter from 'vue-router'

import Login from '../views/Login.vue'
import VerifyWithPassword from '../views/VerifyWithPassword'
import RequestPasswordReset from '../views/RequestPasswordReset'
import RequestPassword from '../views/ResetPassword'

import store from '../store'

var log = require('debug')('app:router')
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/home',
    name: 'home',
    redirect: to => {
      const user = store.getters['auth/user']

      if (user.isAdmin) {
        return { name: 'shops' }
      }

      if (user.isRegionAdmin) {
        return { name: 'shops' }
      }

      if (user.isShopAdmin) {
        return { name: 'shop', params: { shopId: user.shop_id } }
      }

      if (user.isShopUser) {
        return { name: 'shop', params: { shopId: user.shop_id } }
      }

      return { name: 'login' }
    }
  },
  {
    path: '/verify',
    name: 'verify',
    component: VerifyWithPassword
  },
  {
    path: '/reset-password-request',
    name: 'reset-password-request',
    component: RequestPasswordReset
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: RequestPassword
  },
  {
    path: '/my-profile',
    name: 'my-profile',
    component: () => import('../views/MyProfile'),
    meta: {
      title: 'My Profile',
      layout: 'LoggedIn',
      requiresAuth: true
    }
  },
  {
    path: '/shops',
    name: 'shops',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Shops'),
    meta: {
      title: 'Shops',
      layout: 'LoggedIn',
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'users',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Users'),
    meta: {
      title: 'Users',
      layout: 'LoggedIn',
      requiresAuth: true,
      adminAuth: true
    }
  },
  {
    path: '/shop/:shopId',
    component: () => import('../views/Shop'),
    props: true,
    meta: {
      title: 'Shop',
      layout: 'LoggedIn',
      requiresAuth: true
    },
    children: [
      {
        path: '',
        name: 'shop',
        component: () => import('../views/shop/ShopInvoices'),
        props: true,
        meta: {
          title: 'Shop Invoices',
          requiresAuth: true,
          layout: 'LoggedIn'
        }
      },
      {
        path: 'invoices',
        name: 'shop-invoices',
        component: () => import('../views/shop/ShopInvoices'),
        props: true,
        meta: {
          title: 'Shop Invoices',
          requiresAuth: true,
          layout: 'LoggedIn'
        }
      },
      {
        path: 'kits',
        name: 'shop-kits',
        component: () => import('../views/shop/ShopKits'),
        props: true,
        meta: {
          title: 'Shop Kits',
          requiresAuth: true,
          layout: 'LoggedIn'
        }
      },
      {
        path: 'products',
        name: 'shop-products',
        component: () => import('../views/shop/ShopProducts'),
        props: true,
        meta: {
          title: 'Shop Products',
          requiresAuth: true,
          layout: 'LoggedIn'
        }
      },
      {
        path: 'techs',
        name: 'shop-techs',
        component: () => import('../views/shop/ShopTechs'),
        props: true,
        meta: {
          title: 'Shop Techs',
          requiresAuth: true,
          layout: 'LoggedIn'
        }
      },
      {
        path: 'users',
        name: 'shop-users',
        component: () => import('../views/shop/ShopUsers'),
        props: true,
        meta: {
          title: 'Shop Users',
          requiresAuth: true,
          layout: 'LoggedIn'
        }
      },
      {
        path: 'orders',
        name: 'shop-orders',
        component: () => import('../views/shop/ShopOrders'),
        props: true,
        meta: {
          title: 'Shop Orders',
          requiresAuth: true,
          layout: 'LoggedIn'
        }
      }
    ]
  },
  {
    path: '/edit-shop/:shopId',
    name: 'edit-shop',
    component: () => import('../views/EditShop'),
    meta: {
      title: 'Edit Shop',
      layout: 'LoggedIn',
      requiresAuth: true
    }
  },
  {
    path: '/global-products',
    name: 'global-products',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/GlobalProducts'),
    meta: {
      title: 'Global Products',
      requiresAuth: true,
      // adminAuth: true,
      layout: 'LoggedIn'
    }
  },
  {
    path: '/product/:productId',
    name: 'product',
    component: () => import('../views/Product'),
    props: true,
    meta: {
      title: 'Product',
      requiresAuth: true,
      layout: 'LoggedIn'
    }
  },
  {
    path: '/edit-product/:productId',
    name: 'edit-product',
    component: () => import('../views/EditProduct'),
    meta: {
      title: 'Edit Product',
      requiresAuth: true,
      layout: 'LoggedIn'
    }
  },
  {
    path: '/user/:userId',
    name: 'user',
    component: () => import('../views/User'),
    props: true,
    meta: {
      title: 'User',
      layout: 'LoggedIn'
    }
  },
  {
    path: '/edit-user/:userId',
    name: 'edit-user',
    component: () => import('../views/EditUser'),
    props: true,
    meta: {
      title: 'Edit User',
      layout: 'LoggedIn'
    }
  },
  {
    path: '/regions',
    name: 'regions',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Regions'),
    meta: {
      title: 'Regions',
      layout: 'LoggedIn',
      adminAuth: true,
      requiresAuth: true
    }
  },
  {
    path: '/region/:regionId',
    name: 'region',
    component: () => import('../views/Region'),
    props: true,
    meta: {
      title: 'Region',
      layout: 'LoggedIn',
      requiresAuth: true
    }
  },
  {
    path: '/edit-region/:regionId',
    name: 'edit-region',
    component: () => import('../views/EditRegion'),
    meta: {
      title: 'Edit Region',
      layout: 'LoggedIn',
      requiresAuth: true
    }
  },
  {
    path: '/techs',
    name: 'techs',
    component: () => import(/* webpackChunkName: "admin" */ '../views/admin/Techs'),
    meta: {
      title: 'Techs',
      layout: 'LoggedIn',
      requiresAuth: true,
      adminAuth: true
    }
  },
  {
    path: '/tech/:techId',
    name: 'tech',
    component: () => import('../views/Tech'),
    props: true,
    meta: {
      title: 'Tech',
      layout: 'LoggedIn',
      requiresAuth: true
    }
  },
  {
    path: '/edit-tech/:techId',
    name: 'edit-tech',
    component: () => import('../views/EditTech'),
    meta: {
      title: 'Edit Tech',
      layout: 'LoggedIn',
      requiresAuth: true
    }
  },
  {
    path: '/invoice/:invoiceId',
    name: 'invoice',
    component: () => import('../views/Invoice'),
    props: true,
    meta: {
      title: 'Invoice',
      layout: 'LoggedIn',
      requiresAuth: true
    }
  },
  {
    path: '/kit/:kitId',
    name: 'kit',
    component: () => import('../views/Kit'),
    props: true,
    meta: {
      title: 'Kit',
      layout: 'LoggedIn',
      requiresAuth: true
    }
  },
  {
    path: '/print-invoice/:invoiceId',
    name: 'print-invoice',
    component: () => import('../views/PrintInvoice'),
    props: true,
    meta: {
      title: 'Print Invoice'
    }
  },
  {
    path: '/edit-kit/:kitId',
    name: 'edit-kit',
    component: () => import('../views/EditKit'),
    meta: {
      title: 'Edit Kit',
      layout: 'LoggedIn',
      requiresAuth: true
    }
  },
  {
    path: '/edit-invoice/:invoiceId',
    name: 'edit-invoice',
    component: () => import('../views/EditInvoice'),
    meta: {
      title: 'Edit Invoice',
      layout: 'LoggedIn',
      requiresAuth: true
    }
  },
  {
    path: '/orders',
    name: 'orders',
    component: () => import('../views/Orders'),
    props: true,
    meta: {
      title: 'Order',
      layout: 'LoggedIn',
      requiresAuth: true,
      adminAuth: true
    }
  },
  {
    path: '/order/:orderId',
    name: 'order',
    component: () => import('../views/Order'),
    props: true,
    meta: {
      title: 'Order',
      layout: 'LoggedIn',
      requiresAuth: true
    }
  },
  {
    path: '/edit-order/:orderId',
    name: 'edit-order',
    component: () => import('../views/EditOrder'),
    meta: {
      title: 'Edit Order',
      layout: 'LoggedIn',
      requiresAuth: true
    }
  },
  {
    path: '/drawers',
    name: 'drawers',
    component: () => import('../views/Drawers'),
    meta: {
      title: 'Drawers',
      layout: 'LoggedIn',
      requiresAuth: true
    }
  },
  {
    path: '/drawer/:drawerId',
    name: 'drawer',
    component: () => import('../views/Drawer'),
    meta: {
      title: 'Drawer',
      layout: 'LoggedIn',
      requiresAuth: true
    }
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/Cart'),
    meta: {
      title: 'Cart',
      layout: 'LoggedIn',
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.title !== undefined) {
    document.title = process.env.VUE_APP_TITLE + ' ' + to.meta.title
  } else {
    document.title = process.env.VUE_APP_TITLE + ' ASP TRACS'
  }

  const isAuthenticated = store.getters['auth/isAuthenticated']
  const user = store.getters['auth/user']
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)

  log(`isAuthenticated: ${isAuthenticated}`)

  const adminAuth = to.matched.some(record => record.meta.adminAuth)

  // If auth is required and there's not current user
  if (requiresAuth && !isAuthenticated) {
    next({ name: 'login', query: { redirect: to.path } })
    log('Requires Auth: not logged in')
    next(false)
  } else if (adminAuth && !user.isAdmin) {
    log('User is not a Admin, denying route')
    next(false)
  } else {
    next()
  }
})

export default router
