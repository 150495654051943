import feathersClient, { makeServicePlugin, BaseModel } from '@/api-client'

class InvoiceRow extends BaseModel {
  static modelName = 'InvoiceRow'

  static instanceDefaults (data, { store, models }) {
    return {
      qty: 1
    }
  }

  get listSubtotal () {
    return this.qty * this.listPrice
  }

  get costSubtotal () {
    return this.qty * this.costPrice
  }
}
const servicePath = 'invoice-rows'
const servicePlugin = makeServicePlugin({
  Model: InvoiceRow,
  // IMPORTANT
  whitelist: ['$eager', '$regex', '$options'],
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
