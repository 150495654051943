import feathersClient, { makeServicePlugin, BaseModel } from '@/api-client'

class User extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'User'

  static instanceDefaults (data, { store, models }) {
    return {
      status: 'ACTIVE'
    }
  }

  static setupInstance (data, { models }) {
    if (data.createdBy) {
      data.createdBy = new models.api.User(data.createdBy)
    }
    // if (data.shop) {
    //   data.shop = new models.api.User(data.shop)
    // }
    // if (data.region) {
    //   data.region = new models.api.User(data.region)
    // }
    return data
  }

  get permissionsDisplay () {
    return this.permissions.toLowerCase().replace('_', ' ')
  }

  get initials () {
    return this.firstName[0] + this.lastName[0]
  }

  get fullName () {
    return this.firstName + ' ' + this.lastName
  }

  get isAdmin () {
    return this.permissions.includes('SUPER_ADMIN')
  }

  get isRegionAdmin () {
    return this.permissions.includes('REGION_ADMIN')
  }

  get isShopAdmin () {
    return this.permissions.includes('SHOP_ADMIN')
  }

  get isShopUser () {
    return this.permissions.includes('SHOP_USER')
  }

  get isShop () {
    return this.permissions.includes('SHOP')
  }

  get isRegional () {
    return this.permissions.includes('REGION')
  }
}
const servicePath = 'users'
const servicePlugin = makeServicePlugin({
  Model: User,
  service: feathersClient.service(servicePath),
  whitelist: ['$eager', '$iRegexp', '$regex', '$options', '$mergeEager'],
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
