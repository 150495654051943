import feathersClient, { makeServicePlugin, BaseModel } from '@/api-client'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
class Invoice extends BaseModel {
  static modelName = 'Invoice'

  static instanceDefaults (data, { store, models }) {
    return {
      date: new Date().toISOString()
    }
  }

  static setupInstance (data, { models }) {
    if (data.tech) {
      data.tech = new models.api.Tech(data.tech)
    }

    return data
  }

  get formattedDate () {
    const invoiceDate = dayjs(this.date).format()
    return dayjs.utc(invoiceDate).format('MM/DD/YY')
  }
}
const servicePath = 'invoices'
const servicePlugin = makeServicePlugin({
  Model: Invoice,
  // IMPORTANT
  whitelist: ['$eager', '$regex', '$options', '$mergeEager'],
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
