import feathersClient, { makeServicePlugin, BaseModel } from '@/api-client'

class Tech extends BaseModel {
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Tech'

  static instanceDefaults (data, { store, models }) {
    return {
    //   shop_id: '35a89448-ca23-4152-a9ea-36f25f71a427'
    }
  }
}
const servicePath = 'techs'
const servicePlugin = makeServicePlugin({
  Model: Tech,
  // IMPORTANT
  whitelist: ['$eager', '$iRegexp', '$regex', '$options'],
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
