<template lang="pug">
  v-container.login.fill-height(fluid)
    v-row(
      align='center'
      justify='center'
    )
      v-col(
        cols='12'
        sm='8'
        md='6'
        lg='4'
        :class="{ 'px-0' : $vuetify.breakpoint.smAndDown }"
      )
        v-card.elevation-12
          v-sheet.pa-4
            v-img(
              src="@/assets/ASP_logo_text.png"
              contain
            )
          v-card-text
            v-form.pa-4(
              ref="form"
              v-model="valid"
              v-on:submit.prevent="login"
            )
              v-sheet.pa-4.mb-6(
                v-if="loginError"
                color="error lighten-2"
              )
                h3.text-center {{ loginError }}
              v-text-field(
                label='Username'
                name='username'
                v-model="userLogin.username"
                filled
                type='text'
                :rules="[rules.required]"
              )
              v-text-field#password(
                label='Password'
                filled
                v-model="userLogin.password"
                name='password'
                type='password'
                :rules="[rules.required]"
              )
              v-btn(
                block
                depressed
                color="primary"
                large
                type="submit"
                :disabled="!valid"
                :loading="sendingLogin"
              ) Login

              .my-4.text-center
                router-link(
                  :to="{ name: 'reset-password-request' }"
                ) Forgot password?

              span.text-caption If your password doesn't work or you dont have an account, please contact us at&nbsp
              a(
                :href="`mailto:customerservice@aspclips.com`"
              ) customerservice@aspclips.com
</template>

<script>
import validations from '@/mixins/validationRulesMixin'

import { mapActions } from 'vuex'
import store from '@/store'

var log = require('debug')('app:authentication')

export default {
  name: 'login',
  mixins: [validations],
  data: () => ({
    userLogin: {
      username: '',
      password: ''
    },
    loginError: '',
    sendingLogin: false
  }),
  created () {
    if (store.getters['auth/isAuthenticated']) {
      this.routeUserAfterLogin()
    }
  },
  methods: {
    ...mapActions('auth', ['authenticate']),
    async login () {
      this.sendingLogin = true
      const self = this

      try {
        const response = await this.authenticate({ ...this.userLogin, strategy: 'local' })
        log('Authentication Success: ', response)

        self.routeUserAfterLogin()
      } catch (error) {
        this.sendingLogin = false
        if (error.message === 'Invalid login') {
          this.loginError = 'Your username and/or password are invalid'
        } else {
          this.loginError = error.message
        }
      }
    },
    routeUserAfterLogin () {
      this.$router.push('/home')
    }
  }
}
</script>

<style lang="scss" scoped>
.login {
  background-image: url('/img/background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
}
</style>
